import React, { useState, useEffect } from 'react';
import axios from 'axios';
import sendWhatsAppMessage from './SendWhatsAppMessage';

const MyQRCode = ({ retailers, qrCodes, setQrCodes, retailerId }) => {
    const [qrData, setQrData] = useState({
        customerName: '',
        mobileNo: '',
        retailerName: retailerId,
        offerLocation: 0,
        amount: 0,
        ourCommission: 0,
        GeneratedAt: new Date().toISOString()
    });

    const [error, setError] = useState("");
    const [formError, setFormError] = useState(""); // For form validation errors
    const [successMessage, setSuccessMessage] = useState(""); // For success messages

    useEffect(() => {
        const retailerData = JSON.parse(localStorage.getItem('retailerData'));
        if (retailerData) {
            setQrData(prevData => ({
                ...prevData,
                retailerName: retailerId // Automatically select the retailer
            }));
        }
    }, [retailerId]);

    const calculateCommission = (commissionRate, amount) => {
        return (amount * commissionRate) / 100;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'mobileNo') {
            if (!/^\d{0,10}$/.test(value)) {
                setError('Mobile No can only contain digits and must be exactly 10 digits.');
            } else if (value.length !== 10) {
                setError('Mobile No must be exactly 10 digits.');
            } else {
                setError(''); // Clear error if valid
            }
        }

        setQrData(prevData => {
            const updatedData = { ...prevData, [name]: value };

            if (name === 'amount' && updatedData.offerLocation) {
                const selectedRetailer = retailers?.find(r => r.id == updatedData.offerLocation);
                if (selectedRetailer) {
                    const commission = calculateCommission(selectedRetailer.shop_Catagory, value);
                    updatedData.ourCommission = commission;
                }
            }

            return updatedData;
        });
    };

    const validateForm = () => {
        let valid = true;
        if (!qrData.customerName || !qrData.mobileNo || !qrData.offerLocation || !qrData.amount) {
            setFormError('Please fill out all required fields.');
            valid = false;
        } else if (error) {
            setFormError(error);
            valid = false;
        } else {
            setFormError('');
        }
        return valid;
    };

    const handleGenerateQrCode = async () => {
        if (!validateForm()) return; // Only proceed if the form is valid

        try {
            const response = await axios.post('https://discodeals.in/api/admin/generate-qr', qrData);
            const newQrCode = response.data;
            setQrCodes([...qrCodes, newQrCode]);

            // Share the QR code via WhatsApp
            await handleShareQrCode(newQrCode);

            setQrData({
                customerName: '',
                mobileNo: '',
                retailerName: retailerId,
                offerLocation: 0,
                amount: 0,
                ourCommission: 0,
                GeneratedAt: new Date().toISOString()
            });

            // Show success alert
            setSuccessMessage('QR Code generated and shared successfully!');
        } catch (error) {
            console.error('QR Code generation or sharing failed', error);
            // Show error alert
            setSuccessMessage('Failed to generate or share the QR Code. Please try again.');
        }
    };

    const handleShareQrCode = async (qr) => {
        try {
            // Generate the QR code and get the URL
            const response = await axios.get('https://discodeals.in/api/qrcode/generate-qr', {
                params: {
                    offerLocation: qr.offerLocation,
                    id: qr.id,
                    retailerName: qr.retailerName
                }
            });
    
            const qrCodeUrl = response.data.qrCodeUrl;
    
            // Send the WhatsApp message
            await sendWhatsAppMessage(qr.mobileNo, qrCodeUrl);
    
            console.log('WhatsApp message sent successfully');
        } catch (error) {
            console.error('Error generating QR code or sending WhatsApp message', error);
            // Show error alert
            setSuccessMessage('Failed to send WhatsApp message. Please try again.');
        }
    };

    return (
        <div className="card mb-4">
            <div className="card-header">
                <h3>Generate QR Code</h3>
            </div>
            <div className="card-body">
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {formError && <div className="alert alert-danger">{formError}</div>}
                <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" name="customerName" value={qrData.customerName} onChange={handleInputChange} className="form-control" placeholder="Customer Name" />
                </div>
                <div className="form-group">
                    <label>Mobile No</label>
                    <input type="text" name="mobileNo" value={qrData.mobileNo} onChange={handleInputChange} className="form-control" placeholder="Mobile No" />
                    {error && <div className="text-danger">{error}</div>}
                </div>

                <div className="form-group">
                    <label>Retailer</label>
                    <select name="retailerName" value={qrData.retailerName} onChange={handleInputChange} className="form-control">
                        {retailers?.filter(retailer => retailer.isActive && retailer.id === retailerId)
                            .map(activeRetailer => (
                                <option key={activeRetailer.id} value={activeRetailer.id}>
                                    {activeRetailer.name} - {activeRetailer.shop_Name}
                                </option>
                            ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Offer Location</label>
                    <select name="offerLocation" value={qrData.offerLocation} onChange={handleInputChange} className="form-control">
                        <option key="0" value="">Select Offer Location</option>
                        {retailers?.filter(retailer => retailer.isActive && retailer.available_to_Offer)
                            .map(activeRetailer => (
                                <option key={activeRetailer.id} value={activeRetailer.id}>
                                    {activeRetailer.name} - {activeRetailer.shop_Name} - {activeRetailer.shop_Catagory}%
                                </option>
                            ))}
                    </select>
                </div>
                <div className="form-group">
                    <label>Amount</label>
                    <input type="number" name="amount" value={qrData.amount} onChange={handleInputChange} className="form-control" placeholder="Amount" />
                </div>
                <div className="form-group">
                    <label>Our Commission</label>
                    <input type="text" name="ourCommission" value={qrData.ourCommission} readOnly className="form-control" placeholder="Our Commission" />
                </div>
                
                    <button onClick={handleGenerateQrCode} className="btn btn-primary mt-3">
                        Generate QR Code
                    </button>
            
            </div>
        </div>
    );
};

export default MyQRCode;
