import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';

const UsedQrCodesTable = ({ qrCodes, setQrCodes, retailers }) => {
    const [usedQrCodes, setUsedQrCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        const fetchUsedQrCodes = async () => {
            try {
                const response = await axios.get('https://discodeals.in/api/Admin');
                console.log('Fetched data:', response.data);  // Add this line
                setUsedQrCodes(response.data);
            } catch (err) {
                setError('Error fetching data.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
    
        fetchUsedQrCodes();
    }, []);

    

    const getRetailerName = (retailerId) => {
        const retailer = retailers.find(retailer => retailer.id === retailerId);
        return retailer ? retailer.name : 'Any Retailer';
    };

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Customer Name',
            selector: row => <>{row.customerName}<br></br>{row.mobileNo}</>,
            sortable: true,
        },
        {
            name: 'Retailer Name',
            selector: row => <>{getRetailerName(row.retailerName)}</>,
            sortable: true,
        },
        {
            name: 'Offer Location',
            selector: row => getRetailerName(row.offerLocation),
            sortable: true,
        },
        {
            name: 'Generated Date',
            selector: row => new Date(row.generatedAt).toLocaleString(),
            sortable: true,
        },
        {
            name: 'Latitude',
            selector: row => row.latitude,
            sortable: true,
        },
        {
            name: 'Longitude',
            selector: row => row.longitude,
            sortable: true,
        },
    ];

    return (
        <div className="container mt-4">
            <h2>Used QR Codes</h2>
            {loading ? (
                <p>Loading...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <DataTable
                    columns={columns}
                    data={usedQrCodes}
                    pagination
                    highlightOnHover
                    striped
                    defaultSortFieldId={1}
                />
            )}
        </div>
    );
};

export default React.memo(UsedQrCodesTable);
