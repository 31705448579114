import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import SummaryComponent from './RetailerSummary'; // Import the SummaryComponent
import { Modal, Button } from 'react-bootstrap'; // Import Bootstrap components

const ManageRetailers = () => {
    const [retailers, setRetailers] = useState([]);
    const [filteredRetailers, setFilteredRetailers] = useState([]);
    const [search, setSearch] = useState('');
    const [newRetailer, setNewRetailer] = useState({
        id: 0,
        name: '',
        mob_No: '',
        address: '',
        shop_Name: '',
        shop_Catagory: '',
        password: '',
        isActive: true,
        available_to_Offer: false
    });

    const [editingRetailer, setEditingRetailer] = useState(null);
    const [selectedRetailer, setSelectedRetailer] = useState(null); // State to manage the selected retailer for summary
    const [showSummaryModal, setShowSummaryModal] = useState(false); // State to manage the modal visibility

    useEffect(() => {
        fetchRetailers();
    }, []);

    const fetchRetailers = async () => {
        try {
            const response = await axios.get('https://discodeals.in/api/admin/retailers');
            setRetailers(response.data);
            setFilteredRetailers(response.data);
        } catch (error) {
            console.error('Error fetching retailers', error);
        }
    };

    const handleRetailerInputChange = (e) => {
        const { name, value } = e.target;
        setNewRetailer({ ...newRetailer, [name]: value });
    };

    const validateRetailer = () => {
        if (!newRetailer.name || !newRetailer.mob_No || !newRetailer.password) {
            alert('Name, Mobile Number, and Password are required.');
            return false;
        }
        return true;
    };

    const handleAddRetailer = async () => {
        if (!validateRetailer()) return;

        try {
            const retailerResponse = await axios.post('https://discodeals.in/api/admin/add-retailer', newRetailer);
            setRetailers([...retailers, retailerResponse.data]);
            setFilteredRetailers([...filteredRetailers, retailerResponse.data]);

            const retailerLogin = {
                id: 0,
                email: newRetailer.mob_No + "@gmail.com",
                password: newRetailer.password,
                role: 'Retailer'
            };

            await axios.post('https://discodeals.in/api/Users/register', retailerLogin);

            setNewRetailer({
                id: 0,
                name: '',
                mob_No: '',
                address: '',
                shop_Name: '',
                shop_Catagory: '',
                password: '',
                isActive: true,
                available_to_Offer: false
            });
        } catch (error) {
            console.error('Error adding retailer', error);
        }
    };

    const handleEditRetailer = (retailer) => {
        setEditingRetailer(retailer);
        setNewRetailer(retailer);
    };

    const handleUpdateRetailer = async () => {
        if (!validateRetailer()) return;

        try {
            const response = await axios.put('https://discodeals.in/api/admin/update-retailer', newRetailer);
            const updatedRetailers = retailers.map(r => r.id === editingRetailer.id ? response.data : r);
            setRetailers(updatedRetailers);
            setFilteredRetailers(updatedRetailers);

            setEditingRetailer(null);
            setNewRetailer({
                id: 0,
                name: '',
                mob_No: '',
                address: '',
                shop_Name: '',
                shop_Catagory: '',
                password: '',
                isActive: true,
                available_to_Offer: false
            });
        } catch (error) {
            console.error('Error updating retailer', error);
        }
    };

    const handleDeleteRetailer = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this retailer?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`https://discodeals.in/api/admin/delete-retailer/${id}`);
            const updatedRetailers = retailers.filter(r => r.id !== id);
            setRetailers(updatedRetailers);
            setFilteredRetailers(updatedRetailers);
        } catch (error) {
            console.error('Error deleting retailer', error);
        }
    };

    const handleSearch = (e) => {
        const value = e.target.value;
        setSearch(value);
        const filtered = retailers.filter(retailer =>
            retailer.name.toLowerCase().includes(value.toLowerCase()) ||
            retailer.mob_No.includes(value) ||
            retailer.address.toLowerCase().includes(value.toLowerCase()) ||
            retailer.shop_Name.toLowerCase().includes(value.toLowerCase()) ||
            retailer.shop_Catagory.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredRetailers(filtered);
    };

    const columns = [
        {
            name: 'Name',
            selector: row => <>
                {row.isActive ? (
                    <span className="text-success">
                        <i className="fas fa-circle"></i>
                    </span>
                ) : (
                    <span className="text-danger">
                        <i className="fas fa-circle"></i>
                    </span>
                )}
                &ensp; {row.name}<br></br>&ensp;&ensp;&ensp;&nbsp;<b>{row.mob_No}</b></>,
            sortable: true,
        },
        {
            name: 'Address',
            selector: row => <>{row.shop_Name} <br /> <b>{row.address}</b></>,
            sortable: true,
        },
        {
            name: 'Percentage',
            selector: row => row.shop_Catagory + "%",
            sortable: true,
        },
        {
            name: 'Available',
            selector: row => row.available_to_Offer ? 'Yes' : 'No',
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <div>
                    <button
                        onClick={() => handleEditRetailer(row)}
                        className="btn btn-sm btn-primary mr-2"
                    >
                        Edit
                    </button>
                    &ensp; &ensp;
                    <button
                        onClick={() => handleDeleteRetailer(row.id)}
                        className="btn btn-sm btn-danger"
                    >
                        Delete
                    </button>
                </div>
            ),
        }
    ];

    const handleRowClick = (row) => {
        setSelectedRetailer(row); // Set the selected retailer for viewing details
        setShowSummaryModal(true); // Show the summary modal
    };

    const closeSummary = () => setShowSummaryModal(false);

    return (
        <div className="card mb-4">
            <div className="card-header">
                <h3>Manage Retailers</h3>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-4">
                        {/* Form for adding/editing retailer */}
                        <div className="form-group">
                            <label>Retailer Name</label>
                            <input
                                type="text"
                                name="name"
                                value={newRetailer.name}
                                onChange={handleRetailerInputChange}
                                className="form-control"
                                placeholder="Retailer Name"
                            />
                        </div>
                        <div className="form-group">
                            <label>Mobile No</label>
                            <input
                                type="text"
                                name="mob_No"
                                value={newRetailer.mob_No}
                                onChange={handleRetailerInputChange}
                                className="form-control"
                                placeholder="Mobile No"
                            />
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input
                                type="text"
                                name="address"
                                value={newRetailer.address}
                                onChange={handleRetailerInputChange}
                                className="form-control"
                                placeholder="Address"
                            />
                        </div>
                        <div className="form-group">
                            <label>Shop Name</label>
                            <input
                                type="text"
                                name="shop_Name"
                                value={newRetailer.shop_Name}
                                onChange={handleRetailerInputChange}
                                className="form-control"
                                placeholder="Shop Name"
                            />
                        </div>
                        <div className="form-group">
                            <label>Shop Category</label>
                            <select
                                name="shop_Catagory"
                                value={newRetailer.shop_Catagory}
                                onChange={handleRetailerInputChange}
                                className="form-control"
                            >
                                <option value="">Select Category</option>
                                <option value="1">1%</option>
                                <option value="2">2%</option>
                                <option value="3">3%</option>
                                {/* Add other percentage options as needed */}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                name="password"
                                value={newRetailer.password}
                                onChange={handleRetailerInputChange}
                                className="form-control"
                                placeholder="Password"
                            />
                        </div>
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="isActive"
                                    checked={newRetailer.isActive}
                                    onChange={() => setNewRetailer({ ...newRetailer, isActive: !newRetailer.isActive })}
                                />
                                &ensp; Active
                            </label>
                        </div>
                        <div className="form-group">
                            <label>
                                <input
                                    type="checkbox"
                                    name="available_to_Offer"
                                    checked={newRetailer.available_to_Offer}
                                    onChange={() => setNewRetailer({ ...newRetailer, available_to_Offer: !newRetailer.available_to_Offer })}
                                />
                                &ensp; Available to Offer
                            </label>
                        </div>
                        <button
                            onClick={editingRetailer ? handleUpdateRetailer : handleAddRetailer}
                            className="btn btn-primary"
                        >
                            {editingRetailer ? 'Update Retailer' : 'Add Retailer'}
                        </button>
                        {editingRetailer && (
                            <button
                                onClick={() => {
                                    setEditingRetailer(null);
                                    setNewRetailer({
                                        id: '',
                                        name: '',
                                        mob_No: '',
                                        address: '',
                                        shop_Name: '',
                                        shop_Catagory: '',
                                        password: '',
                                        isActive: true,
                                        available_to_Offer: false
                                    });
                                }}
                                className="btn btn-secondary ml-2"
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                    <div className="col-md-8">
                        {/* Search and DataTable */}
                        <div className="form-group">
                            <input
                                type="text"
                                value={search}
                                onChange={handleSearch}
                                className="form-control"
                                placeholder="Search retailers"
                            />
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredRetailers}
                            pagination
                            highlightOnHover
                            pointerOnHover
                            onRowClicked={handleRowClick} // Handle row click for viewing details
                        />
                    </div>
                </div>
            </div>

   {/* Modal for showing retailer summary */}
   {showSummaryModal && (
                <div className="modal show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg"> {/* Use 'modal-lg' for large size or 'modal-xl' for extra large */}
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Retailer Summary</h5>
                          
                            <button type="button" className="close" onClick={closeSummary} style={{ border: 'none', background: 'transparent', fontSize: '1.5rem', outline: 'none' }}>
                                <span>&times;</span>
                            </button>

                        </div>
                        <div className="modal-body">
                            <SummaryComponent retailer={selectedRetailer} key={selectedRetailer.id} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={closeSummary}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
            )}
        </div>
    );
};

export default ManageRetailers;
