import React from 'react';
import QRCode from 'qrcode.react';
import axios from 'axios';
import sendWhatsAppMessage from './SendWhatsAppMessage'; // Import the sendWhatsAppMessage function


const QRCodes = ({ qrCodes, setQrCodes, retailers }) => {
    const handleDeleteQrCode = async (id) => {
        try {
            await axios.delete(`https://discodeals.in/api/admin/delete-qr-code-admin/${id}`);
            setQrCodes(qrCodes.filter(qr => qr.id !== id));
        } catch (error) {
            console.error('Error deleting QR code', error);
        }
    };

    const handleDownloadQrCode = (id) => {
        const canvas = document.getElementById(`qrCodeCanvas-${id}`);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr_code_${id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleShareQrCode = async (qr) => {
        try {
            // Generate the QR code and get the URL
            const response = await axios.get('https://discodeals.in/api/qrcode/generate-qr', {
                params: {
                    offerLocation: qr.offerLocation,
                    id: qr.id,
                    retailerName: qr.retailerName
                }
            });
    
            const qrCodeUrl = response.data.qrCodeUrl;
    
            // Send the WhatsApp message
            await sendWhatsAppMessage(qr.mobileNo, qrCodeUrl);
    
            console.log('WhatsApp message sent successfully');
        } catch (error) {
            console.error('Error generating QR code or sending WhatsApp message', error);
        }
    };

    const getRetailerName = (retailerId) => {
        const retailer = retailers.find(retailer => retailer.id === retailerId);
        return retailer ? retailer.name : 'Any Retailer';
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    return (
        <div className="card mb-4">
            <div className="card-header">
                <h3>QR Codes</h3>
            </div>
            <div className="card-body">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Customer Name</th>
                            <th>Retailer To Offer L.</th>
                            <th>Generated Date</th>
                            <th>QR Code</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {qrCodes.map(qr => (
                            <tr key={qr.id}>
                                <td>{qr.customerName}<br /><b>{qr.mobileNo}</b></td>
                                <td>{getRetailerName(qr.retailerName)} <b>To</b> <br /> {getRetailerName(qr.offerLocation)}</td>
                                <td>{formatDate(qr.generatedAt)}</td>
                                <td>
                                    <QRCode
                                        id={`qrCodeCanvas-${qr.id}`}
                                        value={`Loc=${qr.offerLocation}&Of=${qr.id}&Fro=${qr.retailerName}`}
                                        size={64}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => handleDeleteQrCode(qr.id)} className="btn btn-sm btn-danger mr-2">Delete</button>
                                    &ensp; &ensp;
                                    <button onClick={() => handleDownloadQrCode(qr.id)} className="btn btn-sm btn-success mr-2">Download</button>
                                    &ensp; &ensp;
                                    <button onClick={() => handleShareQrCode(qr)} className="btn btn-sm btn-primary">Share</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default QRCodes;
