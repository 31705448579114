import React, { useState, useEffect } from 'react';
import axios from 'axios';

const RetailerSummary = ({ retailer, onClose }) => {
    const [retailerId] = useState(retailer.id);
    const [retailerData, setRetailerData] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [usedQRCodes, setUsedQRCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [summaryError, setSummaryError] = useState(null);
    const [customersError, setCustomersError] = useState(null);
    const [qrCodesError, setQrCodesError] = useState(null);

    useEffect(() => {
        const fetchRetailerData = async () => {
            setLoading(true);

            try {
                const [summaryResponse, customersResponse, qrCodesResponse] = await Promise.all([
                    axios.get(`https://discodeals.in/api/Retailer/retailer-summary/${retailerId}`).catch(err => {
                        setSummaryError(err);
                        return { data: null }; // Return a default value to avoid breaking
                    }),
                    axios.get(`https://discodeals.in/api/Retailer/retailer-customers/${retailerId}`).catch(err => {
                        setCustomersError(err);
                        return { data: [] }; // Return a default value to avoid breaking
                    }),
                    axios.get(`https://discodeals.in/api/Retailer/retailer-used-qrcodes/${retailerId}`).catch(err => {
                        setQrCodesError(err);
                        return { data: [] }; // Return a default value to avoid breaking
                    })
                ]);

                if (summaryResponse.data) {
                    setRetailerData(summaryResponse.data);
                }

                if (customersResponse.data) {
                    setCustomers(customersResponse.data);
                }

                if (qrCodesResponse.data) {
                    setUsedQRCodes(qrCodesResponse.data);
                }
                
            } catch (err) {
                console.error('Error loading data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchRetailerData();
    }, [retailerId]);

    if (loading) return <p>Loading...</p>;

    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-12 col-12 mb-4">
                    <div className="card">
                        <div className="card-header text-center bg-primary text-white">
                            <h3>{retailer.name} Summary</h3>
                            <span>Mob: {retailer.mob_No} | Address: {retailer.address} | Shop: {retailer.shop_Name} | Percentage: {retailer.shop_Catagory}%</span>
                        </div>
                        <div className="card-body">
                            {summaryError ? (
                                <div className="alert alert-danger">Error loading retailer summary: {summaryError.message}</div>
                            ) : (
                                retailerData && (
                                    <>
                                        <center><h5 className="card-title">Total Amount: ₹{retailerData.totalAmount} &ensp;&ensp; Total Commission: ₹{retailerData.totalCommission} &ensp;&ensp; Total Customers: {retailerData.totalCustomers}</h5></center>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-4">
                    <div className="card">
                        <div className="card-header text-center bg-success text-white">
                            <h4>Customer List</h4>
                        </div>
                        <div className="card-body">
                            {customersError ? (
                                <div className="alert alert-danger">Error loading customers: {customersError.message}</div>
                            ) : (
                                customers.length > 0 ? (
                                    <ul className="list-group">
                                        {customers.map((customer, index) => (
                                            <li key={index} className="list-group-item">
                                                <strong>{customer.customerName}</strong><br />
                                                Mobile: {customer.mobileNo}<br />
                                                Amount: ₹{customer.amount}<br />
                                                Commission: ₹{customer.our_Commission}<br />
                                                Generated At: {new Date(customer.generatedAt).toLocaleString()}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No customers found.</p>
                                )
                            )}
                        </div>
                    </div>
                </div>

                <div className="col-md-6 col-12 mb-4">
                    <div className="card">
                        <div className="card-header text-center bg-warning text-dark">
                            <h4>Used QR Codes</h4>
                        </div>
                        <div className="card-body">
                            {qrCodesError ? (
                                <div className="alert alert-danger">Error loading QR codes: {qrCodesError.message}</div>
                            ) : (
                                usedQRCodes.length > 0 ? (
                                    <ul className="list-group">
                                        {usedQRCodes.map((qrCode, index) => (
                                            <li key={index} className="list-group-item">
                                                <strong>{qrCode.customerName}</strong><br />
                                                Mobile: {qrCode.mobileNo}<br />
                                                Used At: {new Date(qrCode.generatedAt).toLocaleString()}<br />
                                                Location: Latitude: {qrCode.latitude}, Longitude: {qrCode.longitude}
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No used QR codes found.</p>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RetailerSummary;
