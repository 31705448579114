import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext, AuthProvider } from './components/AuthContext';
import Login from './components/Login';
import AdminDashboard from './components/AdminDashboard';
import RetailerDashboard from './components/RetailerDashboard';

const App = () => {
    const { isAuthenticated, role } = useContext(AuthContext);
    const [initialRole, setInitialRole] = useState(null);
    var storedRole;

    useEffect(() => {
        storedRole = localStorage.getItem('authToken');
        if (storedRole) {
            setInitialRole(storedRole);
        }
    }, [isAuthenticated]);

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<Login />} />
                {initialRole ? (
                    <>
                        <Route path="/" element={initialRole === 'Admin' ? <AdminDashboard /> : <RetailerDashboard />} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </>
                ) : (
                    <Route path="/" element={<Navigate to="/login" />} />
                )}
            </Routes>
        </Router>
    );
};

// Wrap the App component with AuthProvider
const AppWrapper = () => (
    <AuthProvider>
        <App />
    </AuthProvider>
);

export default AppWrapper;
