import React, { useState, useRef, useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';
import axios from 'axios';

const QrScanner = ({ retailerId }) => {
    const [scanner, setScanner] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [qrData, setQrData] = useState(null);

    const videoContainerRef = useRef(null);

    useEffect(() => {
        startCamera(); // Start the camera immediately without fetching location

        return () => {
            stopCamera();
        };
    }, []);

    const startCamera = () => {
        if (!scanner && videoContainerRef.current) {
            const qrScanner = new Html5QrcodeScanner(
                "qr-reader",
                { fps: 10, qrbox: 250 },
                false
            );
            qrScanner.render(onScanSuccess, onScanError);
            setScanner(qrScanner);
        }
    };

    const stopCamera = () => {
        if (scanner) {
            scanner.clear().catch(err => console.error('Error stopping scanner:', err));
            setScanner(null);
        }
    };

    const onScanSuccess = async (decodedText, decodedResult) => {
        setQrData(decodedText);
        handleScan(decodedText);
    };

    const onScanError = () => {
        setErrorMessage('Failed to scan QR code.');
    };

    const parseQRCodeContent = (content) => {
        const params = new URLSearchParams(content);
        const Loc = params.get('Loc');
        const Of = params.get('Of');
        const Fro = params.get('Fro');
        return { Loc, Of, Fro };
    };

    const handleScan = async (decodedText) => {
        setLoading(true);
        const { Loc, Of, Fro } = parseQRCodeContent(decodedText);

        if (Loc && Of && Fro) {
            if (retailerId === Loc) {
                try {
                    const response = await axios.get(`https://discodeals.in/api/admin/qr-code-verify`, {
                        params: { Loc, Of, Fro }
                    });

                    if (response.data.valid) {
                        const latitude = 0;  // Set latitude to 0
                        const longitude = 0; // Set longitude to 0

                        if (window.confirm(`QR Code is valid. Do you want to use it? Latitude: ${latitude}, Longitude: ${longitude}`)) {
                            await axios.delete(`https://discodeals.in/api/admin/delete-qr-code/${Of}`, {
                                data: {
                                    latitude: latitude,
                                    longitude: longitude
                                }
                            });
                            alert('QR Code used successfully.');
                        } else {
                            alert('QR Code usage canceled.');
                        }
                    } else {
                        alert('Invalid QR Code.');
                    }

                } catch (error) {
                    console.error('Error validating QR code', error);
                    alert('Failed to validate QR code.');
                }
            } else {
                alert('You do not have permission to scan this QR Code.');
            }
        } else {
            alert('QR Code content is invalid.');
        }

        setLoading(false);
    };

    return (
        <div>
            <h2>QR Code Scanner</h2>
            <div id="qr-reader" style={{ width: '100%' }} ref={videoContainerRef}></div>
            {loading && <p>Processing QR Code...</p>}
            {errorMessage && <p>{errorMessage}</p>}
        </div>
    );
};

export default React.memo(QrScanner);
