import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MyQRCode from './MyQRCode';
import UsageHistory from './UsageHistory';
import QrScanner from './QrScanner';
import { useNavigate } from 'react-router-dom';
import './RetailerDashboard.css';
import axios from 'axios';
import { FaStar } from 'react-icons/fa'; // Star icon for points

const RetailerDashboard = () => {
    const [activeSection, setActiveSection] = useState('myQRCode');
    const [retailerData, setRetailerData] = useState(null);
    const [qrCodes, setQrCodes] = useState([]);
    const [retailers, setRetailers] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchQRCodes = async () => {
            try {
                const response = await axios.get('https://discodeals.in/api/admin/qr-codes');
                setQrCodes(response.data || []);  // Ensure qrCodes is always an array
            } catch (error) {
                console.error('Error fetching QR codes', error);
            }
        };

        const fetchRetailers = async () => {
            try {
                const response = await axios.get('https://discodeals.in/api/admin/retailers');
                setRetailers(response.data || []);  // Ensure retailers is always an array
            } catch (error) {
                console.error('Error fetching retailers', error);
            }
        };

        const data = JSON.parse(localStorage.getItem('retailerData'));
        setRetailerData(data);

        fetchQRCodes();
        fetchRetailers();
    }, []);

    const handleSectionChange = (section) => {
        setActiveSection(section);
    };

    const handleLogout = () => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('retailerData');
        navigate('/login');
    };

    const getInitials = (name) => {
        return name.split(' ').map(part => part[0]).join('');
    };

    return (
        <div className="container mt-4">
            <div className="d-flex flex-column align-items-center mb-3">
                <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                    <div className="d-flex align-items-center">
                        {retailerData && (
                            <div className="profile-icon rounded-circle d-flex align-items-center justify-content-center mr-2">
                                <span className="text-white">{getInitials(retailerData.name)}</span>
                            </div>
                        )}
                        &ensp;
                        <div>
                            <h5 className="mb-0 mt-2">{retailerData ? retailerData.name : 'Loading...'}</h5>
                            {retailerData && (
                                <p className="">
                                    <span className='mt-2'>QR Points: </span>
                                    <span className="font-weight-bold">0  <FaStar className="text-warning mb-1" /></span>
                                </p>
                            )}
                        </div>
                    </div>
                    <button 
                        type="button" 
                        className="btn btn-danger btn-sm"
                        onClick={handleLogout}
                    >
                        Logout
                    </button>
                </div>
            </div>

            <div className="btn-group d-flex justify-content-center mb-3" role="group" aria-label="Retailer dashboard controls">
                <button 
                    type="button" 
                    className={`btn btn-outline-primary ${activeSection === 'myQRCode' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('myQRCode')}
                >
                    My QR Codes
                </button>
                
                {retailerData?.available_to_Offer && (
                <button 
                    type="button" 
                    className={`btn btn-outline-primary ${activeSection === 'scanQRCode' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('scanQRCode')}
                >
                    Scan QR Code
                </button>
            )}
                <button 
                    type="button" 
                    className={`btn btn-outline-primary ${activeSection === 'history' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('history')}
                >
                    Usage History
                </button>
            </div>

            <div className="content">
                {activeSection === 'myQRCode' && (
                    qrCodes.length > 0 ?
                        <MyQRCode retailers={retailers} qrCodes={qrCodes} setQrCodes={setQrCodes} retailerId={retailerData.id} />
                        : <p>No QR codes found. Please check back later.</p>
                )}
                {activeSection === 'scanQRCode' && <QrScanner retailerId={retailerData.id} />}
                {activeSection === 'history' && <UsageHistory retailers={retailers} retailerId={retailerData.id} />}
            </div>
        </div>
    );
};

export default React.memo(RetailerDashboard);
