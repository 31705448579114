import React, { useState, useContext,useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from './AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState("Retailer"); // State to store the selected role
    const [error, setError] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const storedRole = localStorage.getItem('authToken');
        if (storedRole) {
            navigate('/');
        }
    }, []);

    const handleLogin = async () => {
        try {
            let response;
            if (role === 'Admin') {
                response = await axios.post('https://discodeals.in/api/Users/login', { email, password, role });
                login("Admin"); // Save role on successful login
                localStorage.setItem('user', JSON.stringify(response.data));
                localStorage.setItem('role', 'Admin'); // Save role in localStorage
                navigate('/'); // Redirect to Admin Dashboard

            } else {
                response = await axios.post('https://discodeals.in/api/Retailer/login', { mob_no: email, password });
                login("Retailer"); // Save role on successful login
                localStorage.setItem('retailerData', JSON.stringify(response.data.retailer));
                localStorage.setItem('role', 'Retailer'); // Save role in localStorage
                navigate('/'); // Redirect to Retailer Dashboard

            } 

        } catch (error) {
            setError('Login failed. Please check your credentials and try again.');
            console.error('Login failed', error);
        }
    };

    return (
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
            <div className="card p-4" style={{ maxWidth: '400px', width: '100%' }}>
                <h2 className="card-title mb-4 text-center">Login</h2>
                {error && <div className="alert alert-danger">{error}</div>}
                <div className="mb-3">
                    <select 
                        className="form-control" 
                        value={role} 
                        onChange={(e) => setRole(e.target.value)}
                    >
                        <option value="Retailer">Retailer</option>
                        <option value="Admin">Admin</option>
                    </select>
                </div>
                <div className="mb-3">
                    <input 
                        type="email" 
                        className="form-control" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Email or Mobile No" 
                    />
                </div>
                <div className="mb-3">
                    <input 
                        type="password" 
                        className="form-control" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        placeholder="Password" 
                    />
                </div>
                <button className="btn btn-primary w-100" onClick={handleLogin}>Login</button>
            </div>
        </div>
    );
};

export default Login;
