import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Retailers from './ManageRetailers';
import GenerateQRCode from './GenerateQRCode';
import QRCodes from './QRCodes';
import QrScanner from './QrScanner';
import UsedQrCodesTable from './UsedQrCodesTable';
import { useNavigate } from 'react-router-dom'; // Ensure useNavigate is imported

const AdminDashboard = () => {
    const [retailers, setRetailers] = useState([]);
    const [qrCodes, setQrCodes] = useState([]);
    const [activeSection, setActiveSection] = useState('retailers');
    const navigate = useNavigate(); // Initialize navigate for redirection

    useEffect(() => {
        fetchQRCodes();
        fetchRetailers();
    }, []);

    const fetchQRCodes = async () => {
        try {
            const response = await axios.get('https://discodeals.in/api/admin/qr-codes');
            setQrCodes(response.data);
        } catch (error) {
            console.error('Error fetching QR codes', error);
        }
    };

    const fetchRetailers = async () => {
        try {
            const response = await axios.get('https://discodeals.in/api/admin/retailers');
            setRetailers(response.data);
        } catch (error) {
            console.error('Error fetching retailers', error);
        }
    };

    const handleSectionChange = async (section) => {
        setActiveSection(section);
        // Fetch data based on the section
        if (section === 'retailers') {
            await fetchRetailers();
        } else if (section === 'generateQRCode') {
            await fetchRetailers(); // Fetch retailers if needed for QR code generation
            await fetchQRCodes(); // Fetch QR codes if needed for QR code generation
        } else if (section === 'qrCodes') {
            await fetchQRCodes();
        } else if (section === 'qrCodeScaner') {
            await fetchQRCodes(); // Fetch QR codes if needed for QR scanner
        } else if (section === 'usedQrCode') {
            await fetchQRCodes(); // Fetch QR codes if needed for used QR codes
        }
    };

    const handleLogout = () => {
        // Clear user authentication tokens or session data
        localStorage.removeItem('authToken'); // Example: removing auth token from local storage
        // Use setTimeout to ensure the logout process completes before redirecting
        setTimeout(() => {
            window.location.reload(); // Refresh the page to ensure a fresh state
        }, 100); // Adjust timeout duration if necessary
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center my-4">
                <h2>Admin Dashboard</h2>
                <button 
                    type="button" 
                    className="btn btn-danger" 
                    onClick={handleLogout}
                >
                    Logout
                </button>
            </div>
            <div className="btn-group mb-3" role="group" aria-label="Admin dashboard controls">
                <button 
                    type="button" 
                    className={`btn btn-primary ${activeSection === 'retailers' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('retailers')}
                >
                    Retailers
                </button>
                <button 
                    type="button" 
                    className={`btn btn-primary ${activeSection === 'generateQRCode' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('generateQRCode')}
                >
                    Generate QR Code
                </button>
                <button 
                    type="button" 
                    className={`btn btn-primary ${activeSection === 'qrCodes' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('qrCodes')}
                >
                    QR Codes
                </button>
                <button 
                    type="button" 
                    className={`btn btn-primary ${activeSection === 'qrCodeScaner' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('qrCodeScaner')}
                >
                    QR Scanner
                </button>
                <button 
                    type="button" 
                    className={`btn btn-primary ${activeSection === 'usedQrCode' ? 'active' : ''}`} 
                    onClick={() => handleSectionChange('usedQrCode')}
                >
                    List Of Customers
                </button>
            </div>
            {activeSection === 'retailers' && <Retailers retailers={retailers} setRetailers={setRetailers} />}
            {activeSection === 'generateQRCode' && <GenerateQRCode retailers={retailers} qrCodes={qrCodes} setQrCodes={setQrCodes} />}
            {activeSection === 'qrCodes' && <QRCodes qrCodes={qrCodes} setQrCodes={setQrCodes} retailers={retailers} />}
            {activeSection === 'qrCodeScaner' && <QrScanner qrCodes={qrCodes} setQrCodes={setQrCodes} retailers={retailers} />}
            {activeSection === 'usedQrCode' && <UsedQrCodesTable qrCodes={qrCodes} setQrCodes={setQrCodes} retailers={retailers} />}
        </div>
    );
};

export default React.memo(AdminDashboard);
