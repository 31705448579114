import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import QRCode from 'qrcode.react';

const UsageHistory = ({ retailerId, retailers }) => {
    const [customers, setCustomers] = useState([]);
    const [usedQRCodes, setUsedQRCodes] = useState([]);
    const [unusedQRCodes, setUnusedQRCodes] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRetailerData = async () => {
            try {
                // Fetch data from multiple endpoints
                const [customersResponse, unusedQrCodesResponse] = await Promise.all([
                    axios.get(`https://discodeals.in/api/Retailer/retailer-customers/${retailerId}`),
                    axios.get(`https://discodeals.in/api/Retailer/retailer-unused-qrcodes/${retailerId}`)
                ]);
        
                // Check for successful responses and update state accordingly
                if (customersResponse.status === 200) {
                    setCustomers(customersResponse.data);
                } else {
                    console.warn(`Unexpected response status for customers: ${customersResponse.status}`);
                }
        
        
                if (unusedQrCodesResponse.status === 200) {
                    setUnusedQRCodes(unusedQrCodesResponse.data);
                } else {
                    console.warn(`Unexpected response status for unused QR codes: ${unusedQrCodesResponse.status}`);
                }
        
            } catch (err) {
                // Handle different types of errors
                if (err.response) {
                    // Server responded with a status code that falls out of the range of 2xx
                    if (err.response.status === 404) {
                        console.error('Data not found (404):', err.message);
                    } else {
                        console.error('Error loading data:', err.message);
                    }
                } else if (err.request) {
                    // The request was made but no response was received
                    console.error('No response received:', err.message);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.error('Error:', err.message);
                }
            } finally {
                setLoading(false);
            }
        };
        

        fetchRetailerData();

    },[] );



    const getRetailerName = (retailerId) => {
        const retailer = retailers.find(retailer => retailer.id === retailerId);
        return retailer ? retailer.shop_Name : 'Any Retailer';
    };

    const handleDownloadQrCode = (id) => {
        const canvas = document.getElementById(`qrCodeCanvas-${id}`);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr_code_${id}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

   

    return (
        <div className="row">
           
            <div className="col-md-6 col-12 mb-4">
                <div className="card">
                    <div className="card-header text-center bg-success text-white">
                        <h4>Unused QR Codes</h4>
                    </div>
                    <div className="card-body">
                       
                            <ul className="list-group">
                                {unusedQRCodes.map((qrCode, index) => (
                                    <li key={index} className="list-group-item">
                                        <strong>{qrCode.customerName}</strong> - {qrCode.mobileNo}<br />
                                        Shop : {getRetailerName(qrCode.offerLocation)} <br />
                                        Date Time : {new Date(qrCode.generatedAt).toLocaleString()}
                                        <QRCode
                                        id={`qrCodeCanvas-${qrCode.id}`}
                                        value={`Loc=${qrCode.offerLocation}&Of=${qrCode.id}&Fro=${qrCode.retailerName}`}
                                        style={{float:'right', marginTop:'-55px'}}
                                        size={64}
                                    />
<br></br>
                                    <button onClick={() => handleDownloadQrCode(qrCode.id)} className="btn btn-sm btn-success mr-2 " >Download</button>
                                 
                                    </li>
                                ))}
                            </ul>
                       
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UsageHistory;
