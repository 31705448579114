// SendWhatsAppMessage.js
import axios from 'axios';

const sendWhatsAppMessage = async (mobileNo, qrCodeLink, messageToSend) => {
    const apiUrl = 'https://web.betablaster.in/api/send';
    const instanceId = '66B89EEF9F1A3';
    const accessToken = '66a7da50402f7';
    var message
    if(message){
    message = messageToSend;
    }else{
    message = "QR Code is valid for 7 Days";
    }
    const mediaUrl = qrCodeLink; // URL of the QR code image
    const filename = 'OfferQr.png';
    const wpMobile = `91${mobileNo}`; // Mobile number with country code

    // Prepare the URL with query parameters
    const urlWithParams = `${apiUrl}?number=${encodeURIComponent(wpMobile)}&type=media&message=${encodeURIComponent(message)}&media_url=${encodeURIComponent(mediaUrl)}&filename=${encodeURIComponent(filename)}&instance_id=${encodeURIComponent(instanceId)}&access_token=${encodeURIComponent(accessToken)}`;

    try {
        // Send a GET request
        const response = await axios.get(urlWithParams);
        alert('WhatsApp message sent:', response.data);
    } catch (error) {
        // alert('Failed to send WhatsApp message:', error);
    }
};

export default sendWhatsAppMessage;
